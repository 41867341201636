@import "variables.scss";
@import "common.scss";
@import "home.scss";
@import "resume.scss";
@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,500,600");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+KR:400,500,600");
@import url("https://fonts.googleapis.com/css?family=Fraunces:400,500,600");

body {
  word-break: normal;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
