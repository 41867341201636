$sans-serif-font: "Noto Sans KR", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;
$serif-font: "Fraunces", "Georgia", serif;

$primaryColor: #222;
$secondaryColor: #222;
$textPrimary: #444;
$textSecondary: #888;
$defaultBackground: #f5f8f3;
$paper: #f5f5ed;

$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-36: 36px;
$font-size-40: 40px;
$font-size-50: 50px;

$small: 375px;
$medium: 769px;
$large: 1440px;
